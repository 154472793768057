* {
  box-sizing: border-box;
}

html {
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  height: 100%;
  margin: 0;
}

div#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

main {
  margin-top: 79px;
  flex: 1 0;
}

/* Navigation */
.eiqdb-header {
  contain: layout;
  position: fixed;
  top: 0;
  z-index: 100;
  box-sizing: border-box;
  display: block;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 2px 27px 0 rgba(0, 0, 0, 0.1);
}

.eiqdb-header-row {
  display: flex;
  align-items: center;
  height: 79px;
  padding: 0 70px;
}

.eiqdb-product-name-wrapper {
  display: flex;
  align-items: center;
}

.eiqdb-logo-link {
  margin-right: 17px;
  display: flex;
  align-items: center;
}

.eiqdb-logo {
  width: 195px;
  height: 35px;
}

.eiqdb-product-name {
  margin-left: 17px;
  color: #4d748d;
  font-family: Oswald;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
}

.eiqdb-product-name-devider {
  width: 1px;
  height: 34px;
  border-left: 1px solid #979797;
}

.eiqdb-nav-section {
  display: flex;
  margin-left: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.alternative-names-table-container .css-rorn0c-MuiTableContainer-root {
  max-height: 600px;
  overflow-y: auto;
}

::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  background: #eee;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(160, 160, 160);
  border-radius: 20px;
}

.add-alternative-names-input .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 5px 10px;
  width: 190px;
}

.draggable-item {
  z-index: 99999;
}

.display-table {
  display: table !important;
}
.display-table-row {
  display: table-row !important;
}
.display-none {
  display: none !important;
}

.height-14-px {
  height: 14px;
  overflow: hidden;
}
.height-auto {
  height: auto;
}
.background-color-f6f6f6 {
  background-color: #f6f6f6;
}
.background-color-ffffff {
  background-color: #ffffff;
}

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
  .display-block-on-print {
    display: block !important;
  }
  .height-auto-on-print {
    height: auto !important;
  }
  .position-relative-on-print {
    position: relative !important;
  }
  .margin-top-0-on-print {
    margin-top: 0 !important;
  }
  .display-table-row-on-print {
    display: table-row !important;
  }
  .rotate-0-deg-on-print {
    transform: rotate(180deg) !important;
  }
  .display-table-on-print {
    display: table !important;
  }
  .background-color-ffffff-on-print {
    background-color: #ffffff;
  }

  #results-page-container {
    padding-top: 0;
  }

  #results-page-list {
    margin-top: 0;
    margin-left: 0;
  }
}
