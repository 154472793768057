.ais-SearchBox {
    margin: auto;
    width: 100%;

    .search-bar__submit {
        background: none;
        border: none;
        position: absolute;
        top: 1.2rem;
        left: 1rem;
        height: 2rem;

        &:hover {
            cursor: pointer;
        }
    }

    .search-bar__submit-icon {
        width: 8px;
        height: 8px;
        stroke: #979797;
        fill: #979797;
    }

    .ais-SearchBox-form {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        max-width: 850px;
        margin: auto;
    }

    .ais-SearchBox-input {
        display: flex;
        width: 100%;
        height: 60px;
        box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.1);
        border: none;
        padding: 0 20px;
        font-family: 'Montserrat', sans-serif;
    }

    .ais-SearchBox-submit {
        position: relative;
        display: flex;
        background: none;
        border: none;
        right: 30px;
        align-self: center;
        padding-inline: unset;

        .ais-SearchBox-submitIcon {
            width: 15px;
            height: 15px;
        }

        &:hover {
            cursor: pointer;
        }
    }

    .ais-SearchBox-reset {
        background: none;
        border: none;

        &:hover {
            cursor: pointer;
        }
    }
}

.ais-Hits-item {
}

.ais-Hits-list {
    list-style-type: none;
    padding-inline-start: 0;
}

.ais-ClearRefinements-button {
    padding: 4px;
    background-color: transparent;
    color: #4D748D;
    font-family: Montserrat, sans-serif;
    font-size: 12px;
    border: none;
    text-decoration: underline;
    cursor: pointer;

}

.ais-ClearRefinements-button--disabled {
    padding: 4px;
    background-color: transparent;
    color: #979797;
    cursor: unset;
    font-family: Montserrat, sans-serif;
    font-size: 12px;
    border: none;
    text-decoration: underline;
}

.ais-RefinementList-showMore {
    background-color: transparent;
    color: #4D748D;
    font-family: Montserrat, sans-serif;
    font-size: 12px;
    border: none;
    text-decoration: underline;
    padding: 8px;
    &:hover {
        background-color: #ececec;
        color:  #1b7db8;
    }
}

.ais-CurrentRefinements-category {
    color: #FFFFFF;
    display: inline-block;
    padding: 5px 10px;
    font-size: 10px;
    font-family: open-sans, sans-serif;
    font-weight: bold;
    border-radius: 4px;
    background-color: #75AED2;

    .ais-CurrentRefinements-delete {
        background-color: transparent;
        color: #FFFFFF;
        border: none;
        cursor: pointer;
        padding-inline: 0;
        padding-block: 0;
    }
}

.ais-CurrentRefinements {
    padding-top: 8px;
    .ais-CurrentRefinements-list {
        list-style-type: none;
        padding-inline-start: 0;
        display: flex;
        gap: 10px;
        flex-wrap: wrap;

        .ais-CurrentRefinements-item {
            display: flex;
            flex-direction: row;
            gap: 8px;
            align-items: center;

            .ais-CurrentRefinements-label {
                font-weight: bold;
                font-size: 14px;
            }
            .ais-CurrentRefinements-category {
                display: flex;
                flex-direction: row;
                gap: 8px;
                align-items: center;
                font-size: 14px;
            }
        }
    }

}

.ais-RefinementList-searchBox {
    padding-bottom: 12px;
}

.ais-RefinementList-list {
    list-style-type: none;
    padding-inline-start: 0;
    margin-block-start: 0;
    margin-block-end: 0;

    .ais-RefinementList-item {

        .ais-RefinementList-label{

            .ais-RefinementList-count{
                display: none;
            }
        }
    }
}

.ais-Pagination {
    margin-top: 8px;
    min-width: 600px;
}

.ais-Pagination-list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    list-style-type: none;
    font-size: 14px;
    text-align: center;

    .ais-Pagination-item--page {
        padding: 5px 10px;

        &:hover {
            background-color:  #979797;
            border-radius: 50%;
            color:  #1b7db8;
        }
    }

    .ais-Pagination-item--selected {
        background-color: #ececec;
        border-radius: 50%;
        color: #1b7db8;
    }

    .ais-Pagination-item--disabled {
        color: white;
    }

    .ais-Pagination-item--previousPage {
        color: #1b7db8
    }

    .ais-Pagination-item--nextPage {
        color: #1b7db8
    }

    .ais-Pagination-item {
        display: flex;
        align-items: center;
        color: #979797;


        .ais-Pagination-link {
            text-decoration: none;
            color: unset;
        }




    }
}