//MateriaL UI Select Element Classes
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@600;700&display=swap");

.form__group {
  font-family: "Oswald";
}

$white: #fff;
.form__group {
  position: relative;
  padding: 15px 0 0;
  margin-top: 10px;
}

.form__field {
  width: 100%;
  border: 0;
  border-bottom: 2px solid $white;
  outline: 0;
  font-size: 40px;
  color: $white;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;

  &::placeholder {
    color: transparent;
  }

  &:placeholder-shown ~ .form__label {
    font-size: 40px;
    cursor: text;
    top: 20px;
  }
}

.form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 15px;
  color: $white;
}

.form__field:focus {
  ~ .form__label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 15px;
    color: $white;
  }
  padding-bottom: 6px;
  border-width: 2px;
  border-image: $white;
  border-image-slice: 1;
}

a[href^="http://maps.google.com/maps"]
{
  display: none !important;
}
a[href^="https://maps.google.com/maps"]
{
  display: none !important;
}

.gmnoprint a,
.gmnoprint span,
.gm-style-cc {
  display: none;
}
.gmnoprint div {
  background: none !important;
}

.css-6hp17o-MuiList-root-MuiMenu-list {
  max-height: 600px;
}

.company-information-table tbody tr th {
  color: #666666;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 14px;
}

.company-information-table tbody tr td {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 14px;
}

.company-information-table {
  border-radius: 5px !important;
  border: 1px solid #e1e1e1 !important;
  box-shadow: none !important;
  margin-top: 30px !important;
}

.company-information-table .MuiTableCell-body {
  padding: 15px 9px !important;
  vertical-align: top !important;
}

.company-information-table .MuiTableCell-body p:first-of-type {
  margin-top: 0 !important;
}

.company-information-table .MuiTableCell-body p:last-of-type {
  margin-bottom: 0 !important;
}

.css-eglki6-MuiLinearProgress-root {
  height: 5px !important;
  border-radius: 4px;
  background-color: #ececec !important;
}

.css-5xe99f-MuiLinearProgress-bar1 {
  background-color: #4d748d !important;
}
